import { initialState } from "../redux/initialState";
export const getRole=(role, lang=initialState?.lang)=>{
    if(role === 'student'){
        return lang?.role_student;
    }else if(role === 'parent'){
        return lang?.role_parent;
    }else if(role === 'head_teacher'){
        return lang?.role_head_teacher;
    }else if(role === 'speaking_teacher'){
        return lang?.role_speaking_teacher;
    }else if(role === 'teacher'){
        return lang?.role_teacher;
    }else if(role === 'company_head'){
        return lang?.role_company_head;
    }else if(role === 'office_manager'){
        return lang?.role_office_manager;
    }else if(role === "content_manager"){
        return "Content Manager";
    }else if(role === "content_master"){
        return "Content Master";
    }else if(role === "accountant"){
        return "Accountant";
    }else if(role === "support"){
        return "Support";
    }else{
        return '';
    }
}
export const getColor=(role)=>{
    if(role == 'text-[student]'){
        return '#06cf9c';
    }else if(role == 'teacher' || role == 'head_teacher'){
        return 'text-[#c89631]';
    }else if(role == 'parent'){
        return 'text-[#7f66ff]';
    }else if(role == 'company_head'){
        return 'text-[#53bdeb]';
    }else if(role == 'office_manager'){
        return 'text-[#007bfc]';
    }else {
        return 'text-[#ee7100]';
    }
}

export const getType = (type)=>{
    if(type === "unit"){
        return "Unit";
    }else if(type === "midterm_end_course"){
        return "Mid & End Course"
    }else if(type === "robo_unit"){
        return "Robo Unit Essay"
    }else if(type === "robo_midterm_end_course"){
        return "Robo Midterm and End of course essay"
    }
}

export const getDropValue = (array, label, value) => {
    let options = [];
    for(const data of array){
        options.push({
            label: data[label],
            value: data[value]
        })
    }
    return options;
}

export function getSATScore(title, score){
    if(title){
        let x = title.toLowerCase();
        if(x.includes("digital sat")){
            let count = score+200;
            if(count > 800){
                return 800
            }else{
                return count
            }
        }else{
            return score
        }
    }else{
        return score
    }  
}

export const getGradeAll = (reviews, autoGrade, grade) => {
    // console.log(reviews, autoGrade, grade)
    let lastGrade = 0
    if(reviews.length == 0){
        lastGrade = grade
    }else{
        if(grade == 0){
            lastGrade = '0';
        }
        let grades = 0;
        for(const data of reviews){
            grades+=data.grade;
        }
        if(autoGrade){
            lastGrade = ((grades+autoGrade.grade)/(reviews.length+1)).toFixed(1)
        }else{
            lastGrade = grade;
        }
    }

    if(lastGrade<10){
        let checkhalf = lastGrade-Math.floor(lastGrade);
        if(checkhalf==0.5){
            return lastGrade;
        }else if(checkhalf == 0){
            return lastGrade;
        }else{
            if(checkhalf >0.5){
                return Math.floor(lastGrade)+1;
            }else{
                return Math.floor(lastGrade)+0.5
            }
        }
    }else{
        return lastGrade;
    }
}

export const checkPasswordStrength=(password)=>{
    var upperCaseRegex = /[A-Z]/g;
    var lowerCaseRegex = /[a-z]/g;
    var numberRegex = /[0-9]/g;
    var specialCharRegex = /[^A-Za-z0-9]/g;
    var latinCharRegex = /^[A-Za-z]*$/;
    var strength = 0;
    if(password?.toLowerCase().includes("celt") ){
        return 0;
    }
    // Check for password length
    if (password.length >= 8) {
      strength += 1;
    }else{
        if(password.length ===0){
            return 0
        }
        return 1;
    }
  
    // Check for uppercase letters
    if (password.match(upperCaseRegex)) {
      strength += 1;
    }
  
    // Check for lowercase letters
    if (password.match(lowerCaseRegex)) {
      strength += 1;
    }
  
    // Check for numbers
    if (password.match(numberRegex)) {
      strength += 1;
    }
  
    // Check for special characters
    if (password.match(specialCharRegex)) {
      strength += 1;
    }
 
    return strength;
}


export const getGrades=(text)=>{
    const match = text.match(/\d+/); 
    if (match) {
        return parseInt(match[0]); 
    } else {
        return 0;
    }
}


export const getCoachStatus=(x)=>{
    if(x?.exam?.status?.status){
        if(+x?.exam?.status?.status ===2 || +x?.assessments?.status?.status ===2){
            return 2;
        }else if(+x?.exam?.status?.status ===1 || +x?.assessments?.status?.status ===1){
            return 1
        }else if(+x?.exam?.status?.status ===0 && +x?.assessments?.status?.status ===0){
            return  0;
        }else{
            return 2
        }
    }else{
       return +x?.assessments?.status?.status;
    }
   
}

export const getExcelFormat=(array)=>{
    let newArr = [];
    for(const data of array){
        newArr.push({
            "Student":data?.student?.full_name,
            "Teacher": data?.teacher?.full_name,
            "Lesson Mode":data?.lesson_mode,
            "Course": data?.course?.title,
            "Fee":data?.fee,
            "Lesson Count": data?.count
        })
    }
    return newArr;
}

export const getGradeCheck=(answares, data)=>{
   
    let check = 0;
    for(const x of answares){
        if((data?.course_id == 2 || data?.course_id ==5) && x?.grade && x?.grade >=40 ){
            check+=1;
        }else if(data?.course_id == 6  && x?.grade && x?.grade >=50){
            check+=1;
        }else if(data?.course_id == 7  && x?.grade && x?.grade >=60){
            check+=1;
        }else if((data?.course_id == 8 || data?.course_id ==9) && x?.grade && x?.grade >=70){
            check+=1;
        }else if ((data?.course_id == 17 || data?.course_id ==18 || data?.course_id ==19) && x?.grade && x?.grade >=3){
            check+=1;
        }else{
            check+=0;
        }
    }
   
    if(check >0){
      return true
    }else{
      return false
    }
  }

  export const getStringGrade=(text)=>{
    if(text){
        const gradeMatch = text.match(/"grade":\s*"([^"]+)"/);
        const gradeValue = gradeMatch ? gradeMatch[1] : null;
        return gradeValue;
    }else{
        return "0";
    }
  }

  export const getTotalStudentCount=(students)=>{
    // console.log("xx", students[0]?.active_enroll)
    if(students.length !=0){
        if(students[0]?.active_enroll || students[0]?.active_enroll == 0){
            let y = 0;
            for(const x of students.filter(x=> x.active_enroll >0)){
                y+= x.active_enroll;
            }
            return y
        }else{
            return "";
        }
    }else{
        return "";
    }
  }