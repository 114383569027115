import ApplicantsSvg from "../svg/ApplicantsSvg";
import BonusesSvg from "../svg/BonusesSvg";
import CertificateSvg from "../svg/CertificateSvg";
import CheckInSvg from "../svg/CheckInSvg";
import CoursesSvg from "../svg/CoursesSvg";
import DashSvg from "../svg/DashSvg";
import EssUngradedSvg from "../svg/EssUngradedSvg";
import EssayReviewSvg from "../svg/EssayReviewSvg";
import EssaySvg from "../svg/EssaySvg";
import ExResSvg from "../svg/ExResSvg";
import ExamSvg from "../svg/ExamSvg";
import GenStatSvg from "../svg/GenStatSvg";
import HWSvg from "../svg/HWSvg";
import HwResultsSvg from "../svg/HwResultsSvg";
import IALExReviewSvg from "../svg/IALExReviewSvg";
import IALExamsSvg from "../svg/IALExamsSvg";
import ListeningSvg from "../svg/ListeningSvg";
import ManagerSvg from "../svg/ManagerSvg";
import MsgSvg from "../svg/MsgSvg";
import ParentsSvg from "../svg/ParentsSvg";
import PaymentDueSvg from "../svg/PaymentDueSvg";
import PublicPageSvg from "../svg/PublicPageSvg";
import ReportsSvg from "../svg/ReportsSvg";
import ResourcesSvg from "../svg/ResourcesSvg";
import RpUngradedSvg from "../svg/RpUngradedSvg";
import SpCheckinSvg from "../svg/SpCheckinSvg";
import SpPracticeSvg from "../svg/SpPracticeSvg";
import SpReviewSvg from "../svg/SpReviewSvg";
import SpeakingSvg from "../svg/SpeakingSvg";
import StStatsSvg from "../svg/StStatsSvg";
import StudentsSvg from "../svg/StudentsSvg";
import TCountingSvg from "../svg/TCountingSvg";
import TStatsSvg from "../svg/TStatsSvg";
import TeacherSvg from "../svg/TeacherSvg";

import { initialState } from "../../redux/initialState";
import ImageSvg from "../svg/ImageSvg";
import ExamCheckSvg from "../svg/ExamCheckSvg";
import TeacherCheckSvg from "../svg/TeacherCheckSvg";
import SupportSvg from "../svg/SupportSvg";
import PriceCalcSvg from "../svg/PriceCalcSvg";
import LoginBlockSvg from "../svg/LoginBlockSvg";
import NavLinkSvg from "../svg/NavLinkSvg";
import ExamkeysSvg from "../svg/ExamkeysSvg";
const lang = initialState.lang;
export const navLinks = (lang) => {
    const studentRole = [
        {
            part: "",
            pages: [
                {
                    title:lang?.navs?.dashboard,
                    path:"/",
                    svg: <DashSvg />
                }
            ],
            line:false
        },
       
        {
           part: lang?.navs?.st_part_1,
           pages: [
                {
                    title: lang?.navs?.gen_exams,
                    path:"/exams",
                    svg: <ExamSvg />
                },
                {
                    title: lang?.navs?.hw_exams,
                    path:"/homework",
                    svg: <HWSvg />
                },
                {
                    title: lang?.navs?.sp_record,
                    path: "/speaking",
                    svg: <SpeakingSvg/>
                },
                {
                    title: lang?.navs?.sp_practice_st,
                    path: "/speaking-practice",
                    svg: <SpPracticeSvg />
                },
                // {
                //     title: "Sp. pr Level Check",
                //     path: "/sp-practice-level-check",
                //     svg: <SpPracticeSvg />
                // },
                {
                    title: lang?.navs?.essay_writing,
                    path: "/essays",
                    svg: <EssaySvg />
                },
                {
                    title: lang?.navs?.ial_exams,
                    path: "/ial-exams",
                    svg: <IALExamsSvg />
                },
           ],
           line:true
        },
        {
            part:  lang?.navs?.st_part_2,
            pages: [
                {
                    title: lang?.navs?.exam_rws,
                    path:"/exam-results",
                    svg: <ExResSvg />
                },
                {
                    title: lang?.navs?.hws_rws,
                    path:"/homework-results",
                    svg: <HwResultsSvg />
                },
                {
                    title: lang?.navs?.sp_rec_rws,
                    path: "/speaking-reviews",
                    svg: <SpReviewSvg />
                },
                {
                    title: lang?.navs?.sp_prc_rws,
                    path: "/speaking-practice-reviews",
                    svg: <SpPracticeSvg />
                },
                {
                    title: lang?.navs?.essay_rws,
                    path: "/essay-reviews",
                    svg: <EssayReviewSvg />
                },
                {
                    title: lang?.navs?.ial_ex_rws,
                    path: "/ial-ex-reviews",
                    svg: <IALExReviewSvg />
                },
            ],
            line:true
        },
        {
            part:  lang?.navs?.st_part_3,
            pages:[
                {
                    title: lang?.navs?.stud_audio,
                    path: "/listenings",
                    svg: <ListeningSvg />
                },
                {
                    title: lang?.navs?.check_ins,
                    path:"/check-in",
                    svg: <CheckInSvg />
                },
                {
                    title: lang?.navs?.courses,
                    path: "/courses",
                    svg: <CoursesSvg />
                },
                {
                    title: lang?.navs?.certificate,
                    path: "/certificates",
                    svg: <CertificateSvg />
                },
                {
                    title: lang?.navs?.downloads,
                    path: "/resources",
                    svg: <ResourcesSvg />
                },
            ],
            line:false
        }
        
    ]
    const parentRole = [
        {
            part: "",
            pages: [
                {
                    title: lang?.navs?.dashboard,
                    path:"/",
                    svg: <DashSvg />
                }
            ],
            line:false
        },
       
        {
           part:  lang?.navs?.pr_part_1,
           pages: [
                {
                    title: lang?.navs?.check_ins,
                    path:"/parent-check-in",
                    svg: <CheckInSvg />
                },
                {
                    title: lang?.navs?.teacher_reviews,
                    path:"/teacher-reviews",
                    svg: <ExamSvg />
                },
                {
                    title: lang?.navs?.exam_results,
                    path:"/exam-results",
                    svg: <ExResSvg />
                },
                {
                    title: lang?.navs?.hw_results,
                    path:"/homework-results",
                    svg: <HwResultsSvg />
                },
           ],
           line:true
        },
        {
            part: lang?.navs?.pr_part_2,
            pages: [
                {
                    title: lang?.navs?.certificate,
                    path: "/certificates",
                    svg: <CertificateSvg />
                },
            ],
            line:false
        }
        
    ]
    const companyHeadRole = [
        {
            part: "",
            pages: [
                {
                    title: lang?.navs?.dashboard,
                    path:"/",
                    svg: <DashSvg />
                },
               
               
            ],
            line:false
        },
       
        {
           part: lang?.navs?.ch_part_1,
           pages: [
            {
                title: lang?.navs?.students,
                path: "/students",
                svg: <StudentsSvg />
            },
            {
                title: lang?.navs?.parents,
                path: "/parents",
                svg: <ParentsSvg />
            },
            {
                title: lang?.navs?.reports,
                path: "/reports",
                svg: <ReportsSvg />
            },
            {
                title: lang?.navs?.payment_due,
                path: "/payment-due",
                svg: <PaymentDueSvg />
            },
           ],
           line:true
        },
        {
           part: "MAIN MENU",
           pages: [
            {
                title: "Price calculator",
                path: "/price-calculator",
                svg: <PriceCalcSvg />
            },
            
            {
                title: lang?.navs?.appointments,
                path: "/ofice-schedule",
                svg: <CheckInSvg />
            },
           
           
            {
                title: "Login Block",
                path: "/login-blocks",
                svg: <LoginBlockSvg />
            },
            {
                title: "Exam Keys",
                path: "/exam-keys",
                svg: <ExamkeysSvg />
            },
            {
                title: lang?.navs?.onlineApply,
                path: "/online-applies",
                svg: <ApplicantsSvg />
            },
           ],
           line:true
        },
        {
            part: lang?.navs?.ht_part_2,
            pages: [
                {
                    title: lang?.navs?.exam_results,
                    path: "/celt-exam-results",
                    svg: <ExamSvg />
                },
                {
                    title: lang?.navs?.hw_results,
                    path: "/celt-homwork-results",
                    svg: <HwResultsSvg />
                },
                {
                    title: lang?.navs?.es_reviews,
                    path: "/celt-essays",
                    svg: <EssayReviewSvg />
                },
                {
                    title: lang?.navs?.ial_reviews,
                    path: "/office-ial-exams",
                    svg: <IALExReviewSvg />
                },
                {
                    title: lang?.navs?.sp_reviews,
                    path: "/celt-speakings",
                    svg: <SpReviewSvg />
                },
                {
                    title: lang?.navs?.sp_practice,
                    path: "/celt-speakings-practice",
                    svg: <SpPracticeSvg />
                },
                {
                    title: lang?.navs?.student_stats,
                    path: "/student-stats",
                    svg: <StStatsSvg />
                },
            ],
            line:true
        },
        {
            part:lang?.navs?.ch_part_2,
            pages:[
                {
                    title: lang?.navs?.teachers,
                    path: "/teachers",
                    svg: <TeacherSvg />
                },
               
                {
                    title: lang?.navs?.t_counting,
                    path: "/office-counting",
                    svg: <TCountingSvg />
                },
                {
                    title: lang?.navs?.teacher_check,
                    path: "/teacher-check",
                    svg: <TeacherCheckSvg />
                },
                {
                    title: lang?.navs?.teacher_stats,
                    path: "/teacher-stats",
                    svg: <TStatsSvg />
                },
            ],
            line:true
        },
        // {
        //     part:lang?.navs?.ch_part_3,
        //     pages: [
        //         {
        //             title: lang?.navs?.reports,
        //             path: "/reports",
        //             svg: <ReportsSvg />
        //         },
        //         // {
        //         //     title: lang?.navs?.rp_ungraded,
        //         //     path: "/reports-ungraded",
        //         //     svg: <RpUngradedSvg />
        //         // },
        //         // {
        //         //     title: lang?.navs?.es_ungraded,
        //         //     path: "/essay-ungraded",
        //         //     svg: <EssUngradedSvg />
        //         // },
        //         // {
        //         //     title: lang?.navs?.sp_ungraded,
        //         //     path: "/speaking-ungraded",
        //         //     svg: <SpPracticeSvg />
        //         // },
    
               
        //     ],
        //     line:true
        // },
        // {
        //     part: lang?.navs?.ch_part_4,
        //     pages:[
        //         {
        //             title: lang?.navs?.cs_check_ins,
        //             path: "/manager-checkins",
        //             svg: <CheckInSvg />
        //         },
        //         // {
        //         //     title: lang?.navs?.sp_check_ins,
        //         //     path: "/speaking-checkin",
        //         //     svg: <SpCheckinSvg />
        //         // },
                
        //     ],
        //     line:true
        // },
        {
            part: lang?.navs?.ch_part_5,
            pages: [
                {
                    title: lang?.s_abroad,
                    path: "/abroad-statistics",
                    svg: <GenStatSvg />
                },
                {
                    title: lang?.navs?.gen_stats,
                    path: "/general-stats",
                    svg: <GenStatSvg />
                },
                {
                    title: lang?.navs?.messages,
                    path: "/messages",
                    svg: <MsgSvg />
                },
                {
                    title: lang?.navs?.it_support,
                    path: "/support-message",
                    svg: <SupportSvg />
                },
                {
                    title: lang?.navs?.ex_excp,
                    path: "/company-exam-exception",
                    svg: <ExamSvg />
                },
                // {
                //     title:  lang?.navs?.coach_excp,
                //     path: "/company-coach-exception",
                //     svg: <HwResultsSvg />
                // },
                // {
                //     title: lang?.navs?.applicants,
                //     path: "/applicants",
                //     svg: <ApplicantsSvg />
                // },
                {
                    title: lang?.navs?.resources,
                    path: "/resources",
                    svg: <ResourcesSvg />
                },
                {
                    title: lang?.navs?.courses,
                    path: "/courses",
                    svg: <CoursesSvg />
                },
                
                {
                    title: lang?.navs?.managers,
                    path: "/office-managers",
                    svg: <ManagerSvg />
                },    
               
                
                {
                    title: lang?.navs?.bonuses,
                    path: "/bonuses",
                    svg: <BonusesSvg />
                },
                {
                    title: lang?.navs?.public_pages,
                    path: "/public-pages",
                    svg: <PublicPageSvg />
                },
            ],
            line:false
        }
        
    ]
    const officeManagerRole = [
        {
            part: "",
            pages: [
                {
                    title: lang?.navs?.dashboard,
                    path:"/",
                    svg: <DashSvg />
                },
               
            ],
            line:false
        },
       
        {
           part: lang?.navs?.ch_part_1,
           pages: [
            {
                title: lang?.navs?.students,
                path: "/students",
                svg: <StudentsSvg />
            },
            {
                title: lang?.navs?.parents,
                path: "/parents",
                svg: <ParentsSvg />
            },
            {
                title: lang?.navs?.reports,
                path: "/reports",
                svg: <ReportsSvg />
            },
            {
                title: lang?.navs?.payment_due,
                path: "/payment-due",
                svg:<PaymentDueSvg />
            },
           ],
           line:true
        },
        {
           part: "MAIN MENU",
           pages: [
            {
                title: "Price calculator",
                path: "/price-calculator",
                svg: <PriceCalcSvg />
            },
            
            {
                title: lang?.navs?.appointments,
                path: "/ofice-schedule",
                svg: <CheckInSvg />
            },
            
            {
                title: "Login Block",
                path: "/login-blocks",
                svg: <LoginBlockSvg />
            },
            {
                title: "Exam Keys",
                path: "/exam-keys",
                svg: <ExamkeysSvg />
            },
            {
                title: lang?.navs?.onlineApply,
                path: "/online-applies",
                svg: <ApplicantsSvg />
            },
           ],
           line:true
        },
        {
            part:lang?.navs?.ht_part_2,
            pages: [
                {
                    title: lang?.navs?.exam_results,
                    path: "/celt-exam-results",
                    svg: <ExamSvg />
                },
                {
                    title: lang?.navs?.hw_results,
                    path: "/celt-homwork-results",
                    svg: <HwResultsSvg />
                },
                {
                    title: lang?.navs?.es_reviews,
                    path: "/celt-essays",
                    svg: <EssayReviewSvg />
                },
                {
                    title: lang?.navs?.ial_reviews,
                    path: "/office-ial-exams",
                    svg: <IALExReviewSvg />
                },
                {
                    title: lang?.navs?.sp_reviews,
                    path: "/celt-speakings",
                    svg: <SpReviewSvg />
                },
                 
                {
                    title: lang?.navs?.sp_practice,
                    path: "/celt-speakings-practice",
                    svg: <SpPracticeSvg />
                },
                {
                    title: lang?.navs?.student_stats,
                    path: "/student-stats",
                    svg: <StStatsSvg />
                },
            ],
            line:true
        },
        {
            part:lang?.navs?.ch_part_2,
            pages:[
                {
                    title: lang?.navs?.teachers,
                    path: "/teachers",
                    svg: <TeacherSvg />
                },
               
                {
                    title: lang?.navs?.teacher_check,
                    path: "/teacher-check",
                    svg: <TeacherCheckSvg />
                },
                {
                    title: lang?.navs?.teacher_stats,
                    path: "/teacher-stats",
                    svg:<TStatsSvg />
                },
            ],
            line:true
        },
        // {
        //     part:lang?.navs?.ch_part_3,
        //     pages:[
        //         {
        //             title: lang?.navs?.reports,
        //             path: "/reports",
        //             svg: <ReportsSvg />
        //         },
        //         // {
        //         //     title: lang?.navs?.rp_ungraded,
        //         //     path: "/reports-ungraded",
        //         //     svg: <RpUngradedSvg />
        //         // },
        //         // {
        //         //     title: lang?.navs?.es_ungraded,
        //         //     path: "/essay-ungraded",
        //         //     svg: <EssUngradedSvg />
        //         // },
        //         // {
        //         //     title: lang?.navs?.sp_ungraded,
        //         //     path: "/speaking-ungraded",
        //         //     svg: <SpPracticeSvg />
        //         // },
        //     ],
        //     line:true
        // },
        // {
        //     part:lang?.navs?.ch_part_4,
        //     pages:[
        //         {
        //             title: lang?.navs?.cs_check_ins,
        //             path: "/manager-checkins",
        //             svg: <CheckInSvg />
        //         },
        //         // {
        //         //     title: lang?.navs?.sp_check_ins,
        //         //     path: "/speaking-checkin",
        //         //     svg: <SpCheckinSvg />
        //         // },
        //     ],
        //     line:true
        // },
        {
            part:lang?.navs?.ch_part_5,
            pages: [
               
                {
                    title: lang?.navs?.gen_stats,
                    path: "/general-stats",
                    svg: <GenStatSvg />
                },
                {
                    title: lang?.navs?.ex_excp,
                    path: "/company-exam-exception",
                    svg: <ExamSvg />
                },
                // {
                //     title:  lang?.navs?.coach_excp,
                //     path: "/company-coach-exception",
                //     svg: <HwResultsSvg />
                // },
                {
                    title: lang?.navs?.messages,
                    path: "/messages",
                    svg: <MsgSvg />
                },
                {
                    title: lang?.navs?.it_support,
                    path: "/support-message",
                    svg: <SupportSvg />
                },
                {
                    title: lang?.navs?.resources,
                    path: "/resources",
                    svg: <ResourcesSvg />
                },
                {
                    title: lang?.navs?.courses,
                    path: "/courses",
                    svg: <CoursesSvg />
                },
               
                {
                    title: lang?.navs?.public_pages,
                    path: "/public-pages",
                    svg: <PublicPageSvg />
                },
            ],
            line:false
        }
    ]
    
    const headTeacherRole = [
        {
            part: "",
            pages: [
                {
                    title: lang?.navs?.dashboard,
                    path:"/",
                    svg: <DashSvg />
                },
               
                {
                    title: "Portal Link",
                    path: "/link-groups",
                    svg: <NavLinkSvg />
                },
            ],
            line:false
        },
        {
            part:lang?.navs?.ht_part_1,
            pages: [
             {
                 title: lang?.navs?.students,
                 path: "/teacher-students",
                 svg: <StudentsSvg />
             },
             {
                title: lang?.navs?.check_ins,
                path: "/teacher-checkins",
                svg: <CheckInSvg />
            },
            {
                title: lang?.navs?.messages,
                path: "/messages",
                svg: <MsgSvg />
            },
            {
                title: lang?.navs?.reports,
                path: "/reports",
                svg: <ReportsSvg />
            },
            {
                title: lang?.navs?.t_counting,
                path: "/teacher-counting",
                svg: <TCountingSvg />
            },
            {
                title: lang?.navs?.student_stats,
                path: "/student-stats",
                svg: <StStatsSvg />
            },
            ],
            line:true
         },
         {
            part:lang?.navs?.ht_part_2,
            pages:[
                {
                    title: lang?.navs?.exam_results,
                    path: "/teacher-exam-results",
                    svg: <ExamSvg />
                },
                {
                    title: lang?.navs?.hw_results,
                    path: "/teacher-hw-results",
                    svg: <HwResultsSvg />
                },
                {
                    title: lang?.navs?.es_reviews,
                    path: "/celt-essays",
                    svg: <EssayReviewSvg />
                },
                {
                    title: lang?.navs?.ial_reviews,
                    path: "/office-ial-exams",
                    svg: <IALExReviewSvg />
                },
                {
                    title: lang?.navs?.sp_reviews,
                    path: "/teacher-speakings",
                    svg: <SpReviewSvg />
                },
                {
                    title: lang?.navs?.sp_practice,
                    path: "/celt-speakings-practice",
                    svg: <SpPracticeSvg />
                },
            ]
         },
        //  {
        //     part:lang?.navs?.ht_part_3,
        //     pages:[
              
        //         // {
        //         //     title: lang?.navs?.rp_ungraded,
        //         //     path: "/reports-ungraded",
        //         //     svg: <RpUngradedSvg />
        //         // },
        //         // {
        //         //     title: lang?.navs?.es_ungraded,
        //         //     path: "/essay-ungraded",
        //         //     svg: <EssUngradedSvg />
        //         // },
        //         {
        //             title: lang?.navs?.sp_ungraded,
        //             path: "/speaking-ungraded",
        //             svg: <SpPracticeSvg />
        //         },
        //     ],
        //     line:true
        //  },
        //  {
        //     part:lang?.navs?.ht_part_4,
        //     pages:[
              
        //         {
        //             title: lang?.navs?.all_students,
        //             path: "/students",
        //             svg: <StudentsSvg />
        //         },
                
        //        {
        //            title: lang?.navs?.all_exam_results,
        //            path: "/celt-exam-results",
        //            svg: <ExamSvg />
        //        },
               
        //        {
        //            title: lang?.navs?.all_hw_results,
        //            path: "/celt-homwork-results",
        //            svg: <HwResultsSvg />
        //        },
                
        //         {
        //             title: lang?.navs?.all_es_reviews,
        //             path: "/teacher-all-essays",
        //             svg: <EssayReviewSvg />
        //         },
        //     ],
        //     line:true
        //  },
         {
             part:lang?.navs?.ht_part_5,
             pages: [
                {
                    title: lang?.navs?.gen_stats,
                    path: "/teacher-gen-stats",
                    svg: <GenStatSvg />
                },
                {
                    title: lang?.navs?.stud_audio,
                    path: "/listenings",
                    svg: <ListeningSvg />
                },
                {
                    title: lang?.navs?.courses,
                    path: "/courses",
                    svg: <CoursesSvg />
                },
                {
                    title: "Support message",
                    path: "/support-message",
                    svg: <MsgSvg />
                },
                {
                    title: lang?.navs?.resources,
                    path: "/resources",
                    svg: <ResourcesSvg />
                },
                {
                    title: lang?.navs?.public_pages,
                    path: "/public-pages",
                    svg: <PublicPageSvg />
                },
                {
                    title: lang?.navs?.it_support,
                    path: "/support-message",
                    svg: <SupportSvg />
                },
             ],
             line:false
         }
    ]
    const teacherRole = [
        {
            part: "",
            pages: [
                {
                    title: lang?.navs?.dashboard,
                    path:"/",
                    svg: <DashSvg />
                },
                
                {
                    title: "Portal Link",
                    path: "/link-groups",
                    svg: <NavLinkSvg />
                },
            ],
            line:false
        },
        {
            part:lang?.navs?.ht_part_1,
            pages: [
                {
                    title: lang?.navs?.students ,
                    path: "/teacher-students",
                    svg: <StudentsSvg />
                },
                {
                    title: lang?.navs?.cs_check_ins,
                    path: "/teacher-checkins",
                    svg: <CheckInSvg />
                },
                {
                    title: lang?.navs?.messages,
                    path: "/messages",
                    svg: <MsgSvg />
                },
                {
                    title: lang?.navs?.t_counting,
                    path: "/teacher-counting",
                    svg: <TCountingSvg />
                },
                {
                    title: lang?.navs?.student_stats,
                    path: "/student-stats",
                    svg: <StStatsSvg />
                },
            ],
            line:true
         },
         {
            part:lang?.navs?.ht_part_2,
            pages:[
                {
                    title: lang?.navs?.exam_results,
                    path: "/teacher-exam-results",
                    svg: <ExamSvg />
                },
                {
                    title: lang?.navs?.hw_results,
                    path: "/teacher-hw-results",
                    svg: <HwResultsSvg />
                },
                 {
                     title: lang?.navs?.es_reviews,
                     path: "/celt-essays",
                     svg: <EssayReviewSvg />
                 },
                 {
                    title: lang?.navs?.ial_reviews,
                    path: "/office-ial-exams",
                    svg: <IALExReviewSvg />
                },
                {
                    title: lang?.navs?.sp_reviews,
                    path: "/teacher-speakings",
                    svg: <SpReviewSvg />
                },
                {
                    title: lang?.navs?.sp_practice,
                    path: "/celt-speakings-practice",
                    svg: <SpPracticeSvg />
                },
            ]
         },
        //  {
        //     part:lang?.navs?.ht_part_3,
        //     pages:[
        //         {
        //             title: lang?.navs?.rp_ungraded,
        //             path: "/reports-ungraded",
        //             svg: <RpUngradedSvg />
        //         },
        //     ],
        //     line:true
        //  },
         {
             part:lang?.navs?.ht_part_5,
             pages: [
                // {
                //     title: lang?.navs?.all_students,
                //     path: "/students",
                //     svg: <StudentsSvg />
                // },
                {
                    title: lang?.navs?.gen_stats,
                    path: "/teacher-gen-stats",
                    svg: <GenStatSvg />
                },
                {
                    title: lang?.navs?.stud_audio,
                    path: "/listenings",
                    svg: <ListeningSvg />
                },
                 {
                     title: lang?.navs?.courses ,
                     path: "/courses",
                     svg: <CoursesSvg />
                 },
                 {
                     title: lang?.navs?.resources,
                     path: "/resources",
                     svg: <ResourcesSvg />
                 },
                 {
                    title: lang?.navs?.public_pages,
                    path: "/public-pages",
                    svg: <PublicPageSvg />
                },
                {
                    title: lang?.navs?.it_support,
                    path: "/support-message",
                    svg: <SupportSvg />
                },
             ],
             line:false
         }
    ]
    const speakingTeacherRole = [
        {
            part: "",
            pages: [
                {
                    title: lang?.navs?.dashboard,
                    path:"/",
                    svg: <DashSvg />
                },
                
            ],
            line:false
        },
        {
            part:lang?.navs?.ht_part_1,
            pages: [
                {
                    title: lang?.navs?.students ,
                    path: "/teacher-students",
                    svg: <StudentsSvg />
                },
                // {
                //     title: lang?.navs?.sp_check_ins,
                //     path: "/speaking-teacher-checkins",
                //     svg: <CheckInSvg />
                // },
                {
                    title: lang?.navs?.messages,
                    path: "/messages",
                    svg: <MsgSvg />
                },
                // {
                //     title: lang?.navs?.t_counting,
                //     path: "/teacher-counting",
                //     svg: <TCountingSvg />
                // },
                {
                    title: lang?.navs?.student_stats,
                    path: "/student-stats",
                    svg: <StStatsSvg />
                },
            ],
            line:true
         },
         {
            part:lang?.navs?.ht_part_2,
            pages:[
                {
                    title: lang?.navs?.exam_results,
                    path: "/teacher-exam-results",
                    svg: <ExamSvg />
                },
                {
                    title: lang?.navs?.hw_results,
                    path: "/teacher-hw-results",
                    svg: <HwResultsSvg />
                },
                 {
                     title: lang?.navs?.es_reviews,
                     path: "/celt-essays",
                     svg: <EssayReviewSvg />
                 },
                 {
                    title: lang?.navs?.ial_reviews,
                    path: "/office-ial-exams",
                    svg: <IALExReviewSvg />
                },
                {
                    title: lang?.navs?.sp_reviews,
                    path: "/teacher-speakings",
                    svg: <SpReviewSvg />
                },
                {
                    title: lang?.navs?.sp_practice,
                    path: "/celt-speakings-practice",
                    svg: <SpPracticeSvg />
                },
            ]
         },
         {
             part:lang?.navs?.ht_part_5,
             pages: [
                {
                    title: lang?.navs?.sp_teacher_checkin,
                    path: "/speaking-teacher-checkins-view",
                    svg: <CheckInSvg />
                },
                {
                    title: lang?.navs?.stud_audio,
                    path: "/listenings",
                    svg: <ListeningSvg />
                },
                 {
                     title: lang?.navs?.courses ,
                     path: "/courses",
                     svg: <CoursesSvg />
                 },
                 {
                     title: lang?.navs?.resources,
                     path: "/resources",
                     svg: <ResourcesSvg />
                 },
                 {
                    title: lang?.navs?.public_pages,
                    path: "/public-pages",
                    svg: <PublicPageSvg />
                },
                {
                    title: lang?.navs?.it_support,
                    path: "/support-message",
                    svg: <SupportSvg />
                },
             ],
             line:false
         }
    ]
    
    const contentRole = [
        {
            part: "",
            pages: [
                {
                    title: "Exams",
                    path:"/",
                    svg: <DashSvg />
                },
                {
                    title: "IAL Exams",
                    path:"/admin-ial-exams",
                    svg: <IALExamsSvg />
                },
                {
                    title: "Sp. Practice",
                    path:"/admin-sp-practice",
                    svg: <SpPracticeSvg />
                },
                {
                    title: "Essays",
                    path:"/admin-essays",
                    svg: <EssaySvg />
                },
                {
                    title: "Speakings",
                    path:"/admin-speaking",
                    svg: <SpeakingSvg />
                },
                {
                    title: "Pooling exam questions",
                    path: "/exam-pooling-questions",
                    svg: <GenStatSvg />
                },
                {
                    title: "Exam categories",
                    path: "/exam-categories",
                    svg: <GenStatSvg />
                },
            ],
            line:false
        },
    ]
    const superAdminRole = [
        {
            part: "",
            pages: [
                {
                    title: "Dashboard",
                    path:"/",
                    svg: <DashSvg />
                },
                
                {
                    title: lang?.navs?.appointments,
                    path: "/exam-schedule",
                    svg: <CheckInSvg />
                },
                {
                    title: "Exam Graded",
                    path: "/admin-exam-results",
                    svg: <HwResultsSvg />
                },
                {
                    title: "Exam PT Graded",
                    path: "/admin-hw-results",
                    svg: <HwResultsSvg />
                },
                {
                    title: "Essay Graded",
                    path: "/admin-essay-reviews",
                    svg: <EssayReviewSvg />
                },
                {
                    title: "Speaking Graded",
                    path: "/admin-speaking-reviews",
                    svg: <SpeakingSvg />
                },
                {
                    title: lang?.navs?.sp_practice,
                    path: "/celt-speakings-practice",
                    svg: <SpPracticeSvg />
                },
                {
                    title: lang?.navs?.ial_reviews,
                    path: "/office-ial-exams",
                    svg: <IALExReviewSvg />
                },
                {
                    title: "Company Pays",
                    path: "/admin-company-pays",
                    svg: <TCountingSvg />
                },
                {
                    title: "General Stats",
                    path: "/admin-general-stats",
                    svg: <TCountingSvg />
                },
                {
                    title: "Exam Exceptions",
                    path:"/admin-exam-exception",
                    svg: <ExamSvg />
                },
                {
                    title: "End Of Course Excp",
                    path:"/endof-exception",
                    svg: <ExamSvg />
                },
                {
                    title: "Coach Exceptions",
                    path:"/admin-coach-exception",
                    svg: <ExamSvg />
                },
                {
                    title: "Failed students exception",
                    path: "/failed-student-exception",
                    svg: <GenStatSvg />
                },
                {
                    title: "Prompts",
                    path: "/admin-prompts",
                    svg: <CoursesSvg />
                },
                {
                    title: "Exam Settings",
                    path:"/admin-exam-settings",
                    svg: <CoursesSvg />
                },
                {
                    title: "Exams",
                    path:"/admin-exams",
                    svg: <ExamSvg />
                },
                {
                    title: "IAL Exams",
                    path:"/admin-ial-exams",
                    svg: <IALExamsSvg />
                },
                {
                    title: "Students",
                    path: "/admin-students",
                    svg: <StudentsSvg />
                },
                {
                    title: "Teachers",
                    path: "/admin-teachers",
                    svg: <TeacherSvg />
                },
                {
                    title: "Parents",
                    path: "/admin-parents",
                    svg: <ParentsSvg />
                },


               
               
                {
                    title: "Staff",
                    path: "/admin-staff",
                    svg: <TeacherSvg />
                },
               
                
                
               
                
                {
                    title: "Sp. Practice",
                    path:"/admin-sp-practice",
                    svg: <SpPracticeSvg />
                },
                {
                    title: "Essays",
                    path:"/admin-essays",
                    svg: <EssaySvg />
                },
                {
                    title: "Speakings",
                    path:"/admin-speaking",
                    svg: <SpeakingSvg />
                },
                {
                    title: "Images",
                    path:"/admin-images",
                    svg: <DashSvg />
                },
                
                
                {
                    title: "Companies",
                    path:"/admin-companies",
                    svg: <CoursesSvg />
                },
                {
                    title: "Managers",
                    path:"/admin-managers",
                    svg: <ManagerSvg />
                },
                {
                    title: "Office managers",
                    path:"/office-managers",
                    svg: <ManagerSvg />
                },
                {
                    title: "Groups",
                    path:"/admin-groups",
                    svg: <ManagerSvg />
                },
                {
                    title: "Allocation",
                    path:"/admin-allocations",
                    svg: <CertificateSvg />
                },
                {
                    title: "Courses",
                    path:"/admin-courses",
                    svg: <CoursesSvg />
                },
                {
                    title: "Lessons",
                    path:"/admin-lessons",
                    svg: <ResourcesSvg />
                },
                {
                    title: "Listenings",
                    path: "/admin-listenings",
                    svg: <ListeningSvg />
                },
                {
                    title: "Lesson Modes",
                    path: "/admin-lesson-modes",
                    svg: <HwResultsSvg />
                },
               
                {
                    title: "Course Rules",
                    path: "/admin-course-rules",
                    svg: <CoursesSvg />
                },
                {
                    title: "Not Assign Enrolls",
                    path: "/admin-not-assign",
                    svg: <CoursesSvg />
                },
                {
                    title: "Resources",
                    path: "/admin-resources",
                    svg: <ResourcesSvg />
                },
                {
                    title: "Unlock Students",
                    path: "/admin-unlock-students",
                    svg: <StudentsSvg />
                },
                {
                    title: "Type Of Students",
                    path: "/admin-student-types",
                    svg: <GenStatSvg />
                },
                {
                    title: "Login Logs",
                    path: "/admin-login-logs",
                    svg: <GenStatSvg />
                },
                {
                    title: "Monthly Coach",
                    path: "/audit-monthly-coach",
                    svg: <GenStatSvg />
                },
                {
                    title: "Weekly Coach",
                    path: "/audit-weekly-coach",
                    svg: <GenStatSvg />
                },
                {
                    title: "Pooling exam questions",
                    path: "/exam-pooling-questions",
                    svg: <GenStatSvg />
                },
                {
                    title: "Exam categories",
                    path: "/exam-categories",
                    svg: <GenStatSvg />
                },
                {
                    title: "Course Requireds",
                    path: "/course-requireds",
                    svg: <GenStatSvg />
                },
                {
                    title: "Trouble Makers",
                    path: "/problem-students",
                    svg: <GenStatSvg />
                },
                {
                    title: "Price Calculator",
                    path: "/admin/price-calculator",
                    svg: <GenStatSvg />
                },
                {
                    title: "Senior teacher requests",
                    path: "/admin-senior-requests",
                    svg: <GenStatSvg />
                },
                
                {
                    title: "Manager companies",
                    path: "/manager-companies",
                    svg: <GenStatSvg />
                },
                // {
                //     title: "Pooling Exams",
                //     path: "/admin/pooling-exams",
                //     svg: <GenStatSvg />
                // },
                {
                    title: lang?.navs?.it_support,
                    path: "/support-message",
                    svg: <SupportSvg />
                },
                {
                    title: "Parent Coach",
                    path: "/admin-report-coach",
                    svg: <SupportSvg />
                },
                {
                    title: "Exception Coach",
                    path: "/admin-exception-coach",
                    svg: <SupportSvg />
                },
                
            ],
            line:false
        },
    ]
    const accountantRole = [
        {
            part: "",
            pages: [
                {
                    title: "Dashboard",
                    path:"/",
                    svg: <DashSvg />
                },
                {
                    title: lang?.navs?.it_support,
                    path: "/support-message",
                    svg: <SupportSvg />
                },
            ]
        }
    ]
    const supportRole = [
        {
            part: "",
            pages: [
                {
                    title: "Dashboard",
                    path:"/",
                    svg: <DashSvg />
                },
                {
                    title: "Essay Graded",
                    path: "/admin-essay-reviews",
                    svg: <EssayReviewSvg />
                },
                {
                    title: "Speaking Graded",
                    path: "/admin-speaking-reviews",
                    svg: <SpeakingSvg />
                },
                // {
                //     title: lang?.navs?.appointments,
                //     path: "/exam-schedule",
                //     svg: <CheckInSvg />
                // },
                {
                    title: "Exam Keys",
                    path: "/exam-keys",
                    svg: <CheckInSvg />
                },
                {
                    title: "Login Block",
                    path: "/login-blocks",
                    svg: <LoginBlockSvg />
                },
                {
                    title: "IT Support",
                    path: "/support-message",
                    svg: <LoginBlockSvg />
                },
            ]
        }
    ]
    const auditorRole = [
        {
            part: "",
            pages: [
                {
                    title: "Dashboard",
                    path:"/",
                    svg: <DashSvg />
                },
                {
                    title: "Monthly Coach",
                    path: "/audit-monthly-coach",
                    svg: <GenStatSvg />
                },
                {
                    title: "Weekly Coach",
                    path: "/audit-weekly-coach",
                    svg: <GenStatSvg />
                },
                {
                    title: "Students",
                    path: "/admin-students",
                    svg: <StudentsSvg />
                },
                {
                    title: "Teachers",
                    path: "/audit-teachers",
                    svg: <TeacherSvg />
                },
            ]
        }
    ]
    return {
        studentRole,
        parentRole,
        companyHeadRole,
        officeManagerRole,
        headTeacherRole,
        teacherRole,
        speakingTeacherRole,
        contentRole,
        superAdminRole,
        accountantRole,
        auditorRole,
        supportRole
    }
}
